
<style scoped lang="less">
.c_guide_teacher {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    margin-bottom: 20px;
  }
  .c_guide_teacher_ul {
    padding: 0 10px;
    li {
      span {
        display: inline-block;
      }
      .label {
        margin-right: 10px;
      }
      margin-bottom: 15px;
    }
  }
}
</style>
<template>
  <div class="c_guide_teacher">
    <div class="stu-module-header">
      <div class="stu-module-title">指导教师</div>
    </div>

    <div v-loading="loading" style="min-height: 100px">
      <ul class="c_guide_teacher_ul" v-if="!message">
        <template v-for="(value, key) in items">
          <li :key="key" v-if="label[key]">
            <span class="label">{{ label[key] }}：</span>
            <span>{{ value }}</span>
          </li>
        </template>
      </ul>
      <div v-else style="padding-top: 25px">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { QueryEduGraduateDesignTeacher } from "@/libs/api/topic";
export default {
  name: "c_guide_teacher",
  data() {
    return {
      loading: false,
      message: null,
      label: {
        name: "姓名",
        jobNo: "工号",
        workTitle: "职称",
        jobCompany: "工作单位",
        phoneNum: "手机电话",
        telNum: "固定电话",
        email: "邮箱",
        qqNum: "QQ",
        wechart: "微信",
        // elecSign: "电子签名",
      },
      items: {
        name: null,
        jobNo: null,
        workTitle: null,
        jobCompany: null,
        phoneNum: null,
        telNum: null,
        email: null,
        qqNum: null,
        wechart: null,
        // elecSign: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      QueryEduGraduateDesignTeacher()
        .then((res) => {
          this.items = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          const { message } = err;
          this.message = message;
        });
    },
  },
};
</script>
